<template>
  <div>
    <v-row>
      <v-col cols="12">
        <CorrectDataTable
          :table-headers="customer_header"
          :table-data="customer_list"
          :page="tablePage"
          :page-limit="tablePageLimit"
          :page-limit-options="tablePageLimitOptions"
          :item-total="total_items"
          :is-loading="load_table"
          :enable-search="false"
          :enable-filter="false"
          :enable-date-range="false"
          :enable-clear-all="false"
          :disable-pagination="load_table"
          :disable-sort="true"
          :actionViewRouteLink="actionViewRouteLink"
          @options-update="onTableChange"
        ></CorrectDataTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CorrectDataTable from '@/components/CorrectDataTable.vue'
import { getData } from '@/api'

export default {
  name: 'Customer',
  components: {
    CorrectDataTable,
  },
  data: () => ({
    customer_header: [],
    customer_list: [],
    load_table: true,
    tablePage: 1,
    tablePageLimit: 10,
    tablePageLimitOptions: [10, 20, 50],
    total_items: 0,
    actionViewRouteLink: 'CustomerDetail',
  }),
  methods: {
    initializeHeader() {
      this.customer_header = [
        {
          text: this.$t('customer.registration_date'),
          value: 'registration_date',
        },
        {
          text: this.$t('customer.customer_id'),
          value: 'id',
        },
        {
          text: this.$t('customer.email'),
          value: 'email',
        },
        {
          text: this.$t('customer.mobile_number'),
          value: 'mobile_number',
        },
        {
          text: this.$t('customer.number_of_agreement'),
          value: 'number_of_agreement',
        },
        {
          text: this.$t('customer.status'),
          value: 'status',
        },
        {
          text: this.$t('worker.approval_status'),
          value: 'approval_status',
        },
        { text: '', value: 'actionView' },
      ]
    },
    async initializeData() {
      this.load_table = true
      try {
        let send_data = { page: this.tablePage - 1, limit: this.tablePageLimit, user_type: 'customer' }
        let params = new URLSearchParams()
        params.set('cms_get_user', JSON.stringify(send_data))
        let get_user_data = await getData(params)
        this.$func.log('-----Get User Data-----')
        this.$func.log(get_user_data)

        if (!Array.isArray(get_user_data)) {
          this.total_items = get_user_data.total

          let temp = []

          for (let i = 0; i < get_user_data.data.length; i++) {
            let data = get_user_data.data[i]
            let temp_data = {
              registration_date: data.create_date,
              id: data.id,
              email: data.email,
              mobile_number: data.mobile,
              status: data.status,
              approval_status: data.user_approved,
              number_of_agreement: 0,
            }

            temp.push(temp_data)
          }

          this.customer_list = temp

          let customer_list = get_user_data.data.map(obj => {
            return obj.id
          })

          params = new URLSearchParams()
          params.set('cms_get_agreement', JSON.stringify({ user_id_list: customer_list }))
          let get_agreement_data = await getData(params)
          this.$func.log('-----Get Agreement Data-----')
          this.$func.log(get_agreement_data)

          let agreement_data = get_agreement_data.data

          if (agreement_data.length > 0) {
            for (let i = 0; i < get_user_data.data.length; i++) {
              let data = get_user_data.data[i]
              let agreement_count = agreement_data.filter(obj => {
                return obj.user_id == data.id
              })
              this.customer_list[i]['number_of_agreement'] = agreement_count.length
            }
          }
        }
        this.load_table = false
      } catch (error) {
        this.$func.log(error)

        this.load_table = false
      }
    },
    onTableChange(options) {
      if (options.itemsPerPage !== this.tablePageLimit) {
        this.tablePage = 1 // reset to first page if view options changed
      } else {
        this.tablePage = options.page
      }
      if (options.sortBy.length > 0) {
        if (options.sortBy[0].indexOf('user_data.') === 0) {
          this.tableSortKey = options.sortBy[0].substring(10)
        } else {
          this.tableSortKey = options.sortBy[0]
        }
      }

      this.tablePageLimit = options.itemsPerPage
      this.initializeData()
    },
  },
  async created() {
    const fetchAllow = await this.checkToken()
    if (fetchAllow) {
      this.initializeHeader()
      this.initializeData()
    }
  },
}
</script>